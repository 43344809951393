const colors = {
  transparent: 'transparent',
  white: '#FFFFFf',
  black: '#000000',
  gray: {
    10: '#F9FAFA',
    25: '#F6F7F9',
    50: '#EEEFF2',
    100: '#E4E7EC',
    200: '#D2D7DF',
    300: '#98A2B3',
    400: '#667085',
    500: '#475467',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828'
  },
  green: {
    50: '#F4FBF4',
    100: '#E5F8E5',
    200: '#CBEECD',
    300: '#A4E0A7',
    400: '#7ACB7A',
    500: '#31A447',
    600: '#4A9046',
    700: '#227332',
    800: '#315A30',
    900: '#284B2A',
    950: '#132814'
  },
  lemon: {
    50: '#F9FEE9',
    100: '#EEFAD0',
    200: '#DFF6A8',
    300: '#BFEB67',
    400: '#93C742',
    500: '#84B839',
    600: '#739F32',
    700: '#577827',
    800: '#466021',
    900: '#3C511F',
    950: '#1E2D0C'
  },
  red: {
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FFCDCA',
    300: '#FDA29B',
    400: '#FA7066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A'
  },
  yellow: {
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E'
  },
  blue: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#B9E6FE',
    300: '#7CD4FD',
    400: '#36BFFA',
    500: '#0BA5EC',
    600: '#0086C9',
    700: '#026AA2',
    800: '#065986',
    900: '#0B4A6F'
  },
  garden: {
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31'
  }
}

export { colors }
