import { i18n } from '@/config/locales'
import { createSharedPathnamesNavigation } from 'next-intl/navigation'

const {
  Link: NextLink,
  redirect,
  permanentRedirect,
  useRouter,
  usePathname
} = createSharedPathnamesNavigation({
  locales: i18n.locales,
  localePrefix: 'as-needed'
})

export { NextLink, redirect, permanentRedirect, useRouter, usePathname }
