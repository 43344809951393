const i18n = {
  defaultLocale: 'pt',
  locales: ['pt', 'en', 'es']
} as const

const htmlLang = {
  pt: 'pt-BR',
  en: 'en',
  es: 'es'
}

export { i18n, htmlLang }
