import Alarm from './alarm.svg'
import ArrowRight from './arrow-right.svg'
import BadgeHereExecut from './badge-here-execut.svg'
import Balcony from './balcony.svg'
import Ball from './ball.svg'
import BankBb from './bank-bb.svg'
import BankBradesco from './bank-bradesco.svg'
import BankCaixaEconomica from './bank-caixa-economica.svg'
import BankItau from './bank-itau.svg'
import Bath from './bath.svg'
import Beach from './beach.svg'
import Bed from './bed.svg'
import Beer from './beer.svg'
import Bike from './bike.svg'
import Blog from './blog.svg'
import BrandArrow2 from './brand-arrow-2.svg'
import BrandArrow3 from './brand-arrow-3.svg'
import BrandArrow from './brand-arrow.svg'
import BrandIcon from './brand-icon.svg'
import BrandLogo from './brand-logo.svg'
import BrandName from './brand-name.svg'
import Brand from './brand.svg'
import Broadcast from './broadcast.svg'
import Building from './building.svg'
import Bus from './bus.svg'
import Calculator from './calculator.svg'
import Camera from './camera.svg'
import Car from './car.svg'
import Card from './card.svg'
import Charger from './charger.svg'
import Chat from './chat.svg'
import Check from './check.svg'
import ChevronDown from './chevron-down.svg'
import ChevronLeft from './chevron-left.svg'
import ChevronRight from './chevron-right.svg'
import ChevronUp from './chevron-up.svg'
import Close from './close.svg'
import Coffee from './coffee.svg'
import Computer from './computer.svg'
import Concierge from './concierge.svg'
import Construction from './construction.svg'
import Cool from './cool.svg'
import Copa from './copa.svg'
import Countertops from './countertops.svg'
import Diamond from './diamond.svg'
import DigitalLocker from './digital-locker.svg'
import Dinner from './dinner.svg'
import DiscountCheck from './discount-check.svg'
import DoubleHouse from './double-house.svg'
import EarthPin from './earth-pin.svg'
import EcoHouse from './eco-house.svg'
import EcoTrash from './eco-trash.svg'
import Edicule from './edicule.svg'
import Elevator from './elevator.svg'
import Error from './error.svg'
import Experiences from './experiences.svg'
import EyeControl from './eye-control.svg'
import Eye from './eye.svg'
import FavoriteSolid from './favorite-solid.svg'
import Favorite from './favorite.svg'
import Fence from './fence.svg'
import FileShield2 from './file-shield-2.svg'
import Film from './film.svg'
import Filter from './filter.svg'
import Fire from './fire.svg'
import Fitness from './fitness.svg'
import FlagEn from './flag-en.svg'
import FlagEs from './flag-es.svg'
import FlagPin from './flag-pin.svg'
import FlagPt from './flag-pt.svg'
import Flag from './flag.svg'
import FullyWired from './fully-wired.svg'
import Game from './game.svg'
import Garage from './garage.svg'
import Garden from './garden.svg'
import Generator from './generator.svg'
import Gourmet from './gourmet.svg'
import Gravel from './gravel.svg'
import Grill from './grill.svg'
import Home2 from './home-2.svg'
import HomeShield3 from './home-shield-3.svg'
import Hospital from './hospital.svg'
import HouseCheck from './house-check.svg'
import HouseDollar from './house-dollar.svg'
import HouseHeart from './house-heart.svg'
import InfoCircle from './info-circle.svg'
import Integration from './integration.svg'
import Intercom from './intercom.svg'
import Iso from './iso.svg'
import Key from './key.svg'
import Ladder from './ladder.svg'
import Lake from './lake.svg'
import Laundry from './laundry.svg'
import Leaf from './leaf.svg'
import Lipstick from './lipstick.svg'
import LocationStar from './location-star.svg'
import Lock from './lock.svg'
import Mail from './mail.svg'
import Man from './man.svg'
import MapIsometric from './map-isometric.svg'
import MapPin from './map-pin.svg'
import Map from './map.svg'
import Medal from './medal.svg'
import Microondas from './microondas.svg'
import Mountain from './mountain.svg'
import Offer from './offer.svg'
import Office from './office.svg'
import ParaibaIsometric from './paraiba-isometric.svg'
import Paraiba from './paraiba.svg'
import Party from './party.svg'
import People from './people.svg'
import Pet from './pet.svg'
import PhoneSolid from './phone-solid.svg'
import Phone from './phone.svg'
import Plant from './plant.svg'
import Play from './play.svg'
import Playground from './playground.svg'
import PoolWater from './pool-water.svg'
import Pool from './pool.svg'
import Quotes from './quotes.svg'
import Restaurant from './restaurant.svg'
import Road from './road.svg'
import Ruller from './ruller.svg'
import SandBall from './sand-ball.svg'
import School from './school.svg'
import Sea from './sea.svg'
import Search from './search.svg'
import Security from './security.svg'
import Services from './services.svg'
import Share from './share.svg'
import ShoppingBag from './shopping-bag.svg'
import Smart from './smart.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialTelegram from './social-telegram.svg'
import SocialThreads from './social-threads.svg'
import SocialTiktok from './social-tiktok.svg'
import SocialTwitter from './social-twitter.svg'
import SocialWhatsapp from './social-whatsapp.svg'
import SocialYoutube from './social-youtube.svg'
import Sofa from './sofa.svg'
import SolarEnergy from './solar-energy.svg'
import Song from './song.svg'
import Soup from './soup.svg'
import Spa from './spa.svg'
import Speakerphone from './speakerphone.svg'
import Square from './square.svg'
import Squash from './squash.svg'
import Stair from './stair.svg'
import Store from './store.svg'
import Suitcase from './suitcase.svg'
import TennisBall from './tennis-ball.svg'
import Thermometer from './thermometer.svg'
import ThumbUp from './thumb-up.svg'
import Topology from './topology.svg'
import Trophy from './trophy.svg'
import Tv from './tv.svg'
import VideoCamera from './video-camera.svg'
import View360 from './view-360.svg'
import View from './view.svg'
import Vigilance from './vigilance.svg'
import Wardrobe from './wardrobe.svg'
import Warehouse from './warehouse.svg'
import Water from './water.svg'
import Webcam from './webcam.svg'
import Whatsapp from './whatsapp.svg'
import Wifi from './wifi.svg'
import Window from './window.svg'
import Www from './www.svg'

const svg = {
  'alarm': Alarm,
  'arrow-right': ArrowRight,
  'badge-here-execut': BadgeHereExecut,
  'balcony': Balcony,
  'ball': Ball,
  'bank-bb': BankBb,
  'bank-bradesco': BankBradesco,
  'bank-caixa-economica': BankCaixaEconomica,
  'bank-itau': BankItau,
  'bath': Bath,
  'beach': Beach,
  'bed': Bed,
  'beer': Beer,
  'bike': Bike,
  'blog': Blog,
  'brand-arrow-2': BrandArrow2,
  'brand-arrow-3': BrandArrow3,
  'brand-arrow': BrandArrow,
  'brand-icon': BrandIcon,
  'brand-logo': BrandLogo,
  'brand-name': BrandName,
  'brand': Brand,
  'broadcast': Broadcast,
  'building': Building,
  'bus': Bus,
  'calculator': Calculator,
  'camera': Camera,
  'car': Car,
  'card': Card,
  'charger': Charger,
  'chat': Chat,
  'check': Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'close': Close,
  'coffee': Coffee,
  'computer': Computer,
  'concierge': Concierge,
  'construction': Construction,
  'cool': Cool,
  'copa': Copa,
  'countertops': Countertops,
  'diamond': Diamond,
  'digital-locker': DigitalLocker,
  'dinner': Dinner,
  'discount-check': DiscountCheck,
  'double-house': DoubleHouse,
  'earth-pin': EarthPin,
  'eco-house': EcoHouse,
  'eco-trash': EcoTrash,
  'edicule': Edicule,
  'elevator': Elevator,
  'error': Error,
  'experiences': Experiences,
  'eye-control': EyeControl,
  'eye': Eye,
  'favorite-solid': FavoriteSolid,
  'favorite': Favorite,
  'fence': Fence,
  'file-shield-2': FileShield2,
  'film': Film,
  'filter': Filter,
  'fire': Fire,
  'fitness': Fitness,
  'flag-en': FlagEn,
  'flag-es': FlagEs,
  'flag-pin': FlagPin,
  'flag-pt': FlagPt,
  'flag': Flag,
  'fully-wired': FullyWired,
  'game': Game,
  'garage': Garage,
  'garden': Garden,
  'generator': Generator,
  'gourmet': Gourmet,
  'gravel': Gravel,
  'grill': Grill,
  'home-2': Home2,
  'home-shield-3': HomeShield3,
  'hospital': Hospital,
  'house-check': HouseCheck,
  'house-dollar': HouseDollar,
  'house-heart': HouseHeart,
  'info-circle': InfoCircle,
  'integration': Integration,
  'intercom': Intercom,
  'iso': Iso,
  'key': Key,
  'ladder': Ladder,
  'lake': Lake,
  'laundry': Laundry,
  'leaf': Leaf,
  'lipstick': Lipstick,
  'location-star': LocationStar,
  'lock': Lock,
  'mail': Mail,
  'man': Man,
  'map-isometric': MapIsometric,
  'map-pin': MapPin,
  'map': Map,
  'medal': Medal,
  'microondas': Microondas,
  'mountain': Mountain,
  'offer': Offer,
  'office': Office,
  'paraiba-isometric': ParaibaIsometric,
  'paraiba': Paraiba,
  'party': Party,
  'people': People,
  'pet': Pet,
  'phone-solid': PhoneSolid,
  'phone': Phone,
  'plant': Plant,
  'play': Play,
  'playground': Playground,
  'pool-water': PoolWater,
  'pool': Pool,
  'quotes': Quotes,
  'restaurant': Restaurant,
  'road': Road,
  'ruller': Ruller,
  'sand-ball': SandBall,
  'school': School,
  'sea': Sea,
  'search': Search,
  'security': Security,
  'services': Services,
  'share': Share,
  'shopping-bag': ShoppingBag,
  'smart': Smart,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-telegram': SocialTelegram,
  'social-threads': SocialThreads,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-whatsapp': SocialWhatsapp,
  'social-youtube': SocialYoutube,
  'sofa': Sofa,
  'solar-energy': SolarEnergy,
  'song': Song,
  'soup': Soup,
  'spa': Spa,
  'speakerphone': Speakerphone,
  'square': Square,
  'squash': Squash,
  'stair': Stair,
  'store': Store,
  'suitcase': Suitcase,
  'tennis-ball': TennisBall,
  'thermometer': Thermometer,
  'thumb-up': ThumbUp,
  'topology': Topology,
  'trophy': Trophy,
  'tv': Tv,
  'video-camera': VideoCamera,
  'view-360': View360,
  'view': View,
  'vigilance': Vigilance,
  'wardrobe': Wardrobe,
  'warehouse': Warehouse,
  'water': Water,
  'webcam': Webcam,
  'whatsapp': Whatsapp,
  'wifi': Wifi,
  'window': Window,
  'www': Www,
}

export type SvgName = keyof typeof svg

export { svg }