import { ThemeConfig } from 'tailwindcss/types/config'

const container = {
  center: true,
  padding: {
    DEFAULT: '1.5rem',
    ['md']: '2rem',
    ['lg']: '2rem',
    ['xl']: '2rem',
    ['1xl']: '2rem',
    ['2xl']: '2rem',
    ['3xl']: '4rem',
    ['4xl']: '4rem',
    ['5xl']: '4rem',
    ['6xl']: '4rem'
  }
} satisfies ThemeConfig['container']

export { container }
