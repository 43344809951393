import { ThemeConfig } from 'tailwindcss/types/config'

const fontSize = {
  'heading-xl': [
    '3.75rem',
    {
      lineHeight: '4rem',
      letterSpacing: '-1.3px'
    }
  ],
  'heading-lg': [
    '3rem',
    {
      lineHeight: '3.4rem',
      letterSpacing: '-0.576px'
    }
  ],
  'heading-md': [
    '2.5rem',
    {
      lineHeight: '2.8rem',
      letterSpacing: '-0.4px'
    }
  ],
  'heading-sm': [
    '2rem',
    {
      lineHeight: '2.5rem',
      letterSpacing: '-0.32px'
    }
  ],
  'heading-xs': [
    '1.5rem',
    {
      lineHeight: '1.8rem',
      letterSpacing: '-0.24px'
    }
  ],
  'heading-2xs': [
    '1.25rem',
    {
      lineHeight: '1.4rem',
      letterSpacing: '-0.20px'
    }
  ],
  'heading-3xs': [
    '1.125rem',
    {
      lineHeight: '1.25rem',
      letterSpacing: '-0.16px'
    }
  ],
  'body-xl': [
    '1.125rem',
    {
      lineHeight: '1.8rem'
    }
  ],
  'body-lg': [
    '1rem',
    {
      lineHeight: '1.4rem'
    }
  ],
  'body-md': [
    '0.875rem',
    {
      lineHeight: '1.3rem'
    }
  ],
  'body-sm': [
    '0.75rem',
    {
      lineHeight: '1.1rem'
    }
  ],
  'body-xs': [
    '0.625rem',
    {
      lineHeight: '0.85rem'
    }
  ],
  'tagline-lg': [
    '0.875rem',
    {
      lineHeight: '1rem',
      letterSpacing: '1px'
    }
  ],
  'tagline-md': [
    '0.75rem',
    {
      lineHeight: '1rem',
      letterSpacing: '1px'
    }
  ],
  'tagline-sm': [
    '0.625rem',
    {
      lineHeight: '1rem',
      letterSpacing: '1px'
    }
  ],
  'label-md': [
    '0.875rem',
    {
      lineHeight: '1.25rem'
    }
  ],
  'label-sm': [
    '0.75rem',
    {
      lineHeight: '1rem'
    }
  ],
  'button-sm': [
    '0.75rem',
    {
      lineHeight: '1rem'
    }
  ],
  'button-md': [
    '0.875rem',
    {
      lineHeight: '1.25rem'
    }
  ],
  'button-lg': [
    '1rem',
    {
      lineHeight: '1.25rem'
    }
  ],
  'button-xl': [
    '1.125rem',
    {
      lineHeight: '1.25rem'
    }
  ],
  'badge-sm': [
    '0.625rem',
    {
      lineHeight: '1rem'
    }
  ],
  'badge-md': [
    '0.75rem',
    {
      lineHeight: '1rem'
    }
  ],
  'badge-lg': [
    '0.875rem',
    {
      lineHeight: '1.25rem'
    }
  ]
} satisfies ThemeConfig['fontSize']

export { fontSize }
