import { ThemeConfig } from 'tailwindcss/types/config'

const backgroundImage = {
  ['none']: 'none',
  ['gradient-to-t']: `linear-gradient(to top, var(--tw-gradient-stops))`,
  ['gradient-to-tr']: `linear-gradient(to top right, var(--tw-gradient-stops))`,
  ['gradient-to-r']: `linear-gradient(to right, var(--tw-gradient-stops))`,
  ['gradient-to-br']: `linear-gradient(to bottom right, var(--tw-gradient-stops))`,
  ['gradient-to-b']: `linear-gradient(to bottom, var(--tw-gradient-stops))`,
  ['gradient-to-bl']: `linear-gradient(to bottom left, var(--tw-gradient-stops))`,
  ['gradient-to-l']: `linear-gradient(to left, var(--tw-gradient-stops))`,
  ['gradient-to-tl']: `linear-gradient(to top left, var(--tw-gradient-stops))`,
  ['primary-soft']: `linear-gradient(180deg, #F2FBF3 0%, #FFFFFF 60%), linear-gradient(0deg, #7ACB7A, #7ACB7A)`,
  ['primary-soft-heading']: `linear-gradient(180deg, #F2FBF3 0%, #FFFFFF 60%)`,
  ['primary-soft-isometric']: `linear-gradient(180deg, #F2FBF3 0%, #F9FAFA 60%)`,
  ['primary-soft-white-isometric']: `linear-gradient(180deg, #F2FBF3 0%, #FFFFFF 60%)`
} satisfies ThemeConfig['backgroundImage']

export { backgroundImage }
